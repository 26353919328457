// Packages
import styled from 'styled-components'

// Components
import Button from '../Button'

export const StyledHomeServiceDisclaimer = styled.section`
  margin: 1.5rem 0;
`

export const StyledWrapper = styled.div`
  padding: 0 1rem;
`

export const StyledSectionTitle = styled.h3`
  text-align: center;
  font-size: 1.2rem;  
  margin-bottom: 1rem;
  width: 100%;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledButton = styled(Button)`  
  width: 10rem;
`
