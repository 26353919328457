// Packages
import styled from 'styled-components'

// Components
import AddressesList from '../AddressesList'

export const StyledContactAddresses = styled.section`
  background-color: rgba(111, 235, 200, .39);
  margin: 1rem 0;
`

export const StyledWrapper = styled.div`
  height: 100%;
  padding: 1.5rem 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
`

export const StyledSectionTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  line-height: 1.3;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: none;
  }
`

export const StyledTitleMapMerged = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    padding: 0;
    width: 60%;
  }
`

export const StyledAddressesList = styled(AddressesList)`
  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    padding: 1rem 0;
    width: 40%;
  }
`
