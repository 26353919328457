// Packages
import React from 'react'
import { graphql } from 'gatsby'

// Components
import AppLayout from '../../layouts/AppLayout'
import SEO from '../../components/presentational/SEO'
import ContactPageContainer from '../../components/container/ContactPageContainer'

const ContactPage = ({ data }) => {
  const banners = [
    {
      mobileImage: data.firstBannerMobile.childImageSharp.fluid,
      desktopImage: data.firstBannerDesktop.childImageSharp.fluid,
      to: null,
      onClick: null
    }        
  ]

  return (
    <AppLayout>
      <SEO title='Contacto' />
      <ContactPageContainer banners={banners} />
    </AppLayout>
  )
}

export const query = graphql`
  query ContactPageQuery {    

    firstBannerMobile: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "contact-banners/mobile/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    firstBannerDesktop: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "contact-banners/desktop/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`

export default ContactPage
