// Packages
import React from 'react'

// Styled components
import { StyledAddressesList, StyledWrapper, StyledAddresses, StyledAddress, StyledParagraph, StyledStrong, StyledAddressTitle, StyledSectionTitle } from './styledComponents'

const AddressesList = props => {
  return (
    <StyledAddressesList {...props}>
      <StyledWrapper>
        <StyledSectionTitle>Estamos ubicados en</StyledSectionTitle>
        <StyledAddresses>
          <StyledAddress>
            <StyledAddressTitle>Matriz Cd. Madero</StyledAddressTitle>
            <StyledParagraph>Ave. Alvaro Obregón 720, Colonia</StyledParagraph>
            <StyledParagraph>Primero de Mayo, Ciudad Madero.</StyledParagraph>
            <StyledParagraph>Tamaulipas.</StyledParagraph>
            <StyledParagraph><StyledStrong>Informes: 833 367 1739</StyledStrong></StyledParagraph>
          </StyledAddress>
          <StyledAddress>
            <StyledAddressTitle>Sucursal Plaza Encinos</StyledAddressTitle>
            <StyledParagraph>Ave. De la Industria 10200</StyledParagraph>
            <StyledParagraph>Colonia Tampico-Altamira</StyledParagraph>
            <StyledParagraph>Tamaulipas.</StyledParagraph>
            <StyledParagraph><StyledStrong>Informes: 833 688 6390</StyledStrong></StyledParagraph>
          </StyledAddress>
          <StyledAddress>
            <StyledAddressTitle>Sucursal Pánuco</StyledAddressTitle>
            <StyledParagraph>Calle San Lerdo de Tejada 219</StyledParagraph>
            <StyledParagraph>Colonia Centro</StyledParagraph>
            <StyledParagraph>Veracruz</StyledParagraph>
            <StyledParagraph><StyledStrong>Informes: 846 102 0487</StyledStrong></StyledParagraph>
          </StyledAddress>
        </StyledAddresses>
      </StyledWrapper>
    </StyledAddressesList>
  )
}

export default AddressesList
