// Packages
import React from 'react'

// Styled components
import { StyledContactPageDisclaimer, StyledWrapper, StyledCopy, StyledSectionTitle, StyledImageContainer, StyledImage } from './styledComponents'

// Assets
import Image from '../../../data/assets/img/laboratorio_doctores_alvarez.jpg'

const ContactPageDisclaimer = () => {
  return (
    <StyledContactPageDisclaimer>
      <StyledWrapper>
        <StyledCopy>
          <StyledSectionTitle>
            Cuidando tu salud y la de tu familia, utilizamos las medidas sanitarias necesarias para tu seguridad dentro de nuestras instalaciones.
          </StyledSectionTitle>
        </StyledCopy>
        <StyledImageContainer>
          <StyledImage src={Image} alt='' />
        </StyledImageContainer>
      </StyledWrapper>
    </StyledContactPageDisclaimer>
  )
}

export default ContactPageDisclaimer
