// Packages
import styled from 'styled-components'

export const StyledBrowserLocationButton = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 2px;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 9px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
`

export const StyledGoogleMaps = styled.section`
  display: flex;
  flex-direction: column;
  height: 30rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.large}px) and (min-height: ${props => props.theme.breakpoints.medium}px) {
    flex-direction: row-reverse;
  }  
`

export const StyledWrapper = styled.div`
  flex-grow: ${props => props.isVisible ? 1 : 0};
  position: relative;
  flex: 1;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.large}px) and (min-height: ${props => props.theme.breakpoints.medium}px) {
    flex-grow: 1;
  }  
`

export const StyledInfoWindow = styled.div``

export const StyledInfoWindowContent = styled.div``

export const StyledName = styled.div`
  margin-bottom: 0.25rem;
`

export const StyledParagraph = styled.p`
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;  
  line-height: 1.3;
  text-align: left;
`

export const StyledStrong = styled.strong`
  font-weight: 700;  
`

export const StyledLink = styled.a`
  display: block;
  margin-top: 0.5rem;
  text-decoration: underline;
`
