// Packages
import React from 'react'

// Styled components
import { StyledHomeServiceDisclaimer, StyledWrapper, StyledSectionTitle, StyledButton, StyledButtonWrapper } from './styledComponents'

const HomeServiceDisclaimer = () => {
  return (
    <StyledHomeServiceDisclaimer>
      <StyledWrapper>
        <StyledSectionTitle>Si necesitas servicio a domicilio nosotros vamos…</StyledSectionTitle>
        <StyledButtonWrapper>
          <StyledButton
            onClick={() => {
              typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank')
            }}
          >
            Agenda tú cita
          </StyledButton>
        </StyledButtonWrapper>
      </StyledWrapper>
    </StyledHomeServiceDisclaimer>
  )
}

export default HomeServiceDisclaimer
