// Packages
import React from 'react'

// Styled components
import { StyledContactPageContent } from './styledComponents'

// Components
import Hero from '../Hero'
import ContactAddresses from '../ContactAddresses'
import ContactPageDisclaimer from '../ContactPageDisclaimer'
import HomeServiceDisclaimer from '../HomeServiceDisclaimer'
import OurClients from '../OurClients'

const ContactPageContent = ({ banners, googleConfig }) => {
  
  return (
    <StyledContactPageContent>
      <Hero slides={banners} />
      <ContactPageDisclaimer />
      <ContactAddresses googleConfig={googleConfig} />
      <HomeServiceDisclaimer />
      <OurClients />
    </StyledContactPageContent>
  )
}

export default ContactPageContent
