// Packages
import React, { useState, useRef } from 'react'
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react'

// Styled components
import { StyledGoogleMaps, StyledWrapper, StyledInfoWindow, StyledInfoWindowContent, StyledParagraph, StyledStrong, StyledName, StyledLink } from './styledComponents'

// Assets
import config from '../../../data/config'

const labsCoordinates = [
  {
    lat: parseFloat(22.24256891683497), // Av. Alvaro obregon 720
    lng: parseFloat(-97.83983932346835),
    placeID: 'ChIJDbsgBdr514URg7PUo3nKj4w',
    city: 'Ciudad Madero',
    colony: 'Col. Primero de Mayo',
    zip: '89450',
    state: 'Tamaulipas',
    street: 'Av Alvaro obregon 720',
    name: 'Matriz Cd. Madero'
  },
  {
    lat: parseFloat(22.324883373047697), // Av de la industria 10200
    lng: parseFloat(-97.87554961800569),
    placeID: 'ChIJt8B651n714URlFMpEGQLY4Q',
    city: 'Altamira',
    colony: 'Tampico-Altamira',
    zip: '89605',
    state: 'Tamaulipas',
    street: 'Av de la industria 10200',
    name: 'Sucursal Plaza Encinos'
  },
  {
    lat: parseFloat(22.0582641), // C san Lerdo de Tejada 219
    lng: parseFloat(-98.182374),
    placeID: 'ChIJlQ5FJ_XA14UR17-iGbTjpxc',
    city: 'Pánuco',
    colony: 'Centro',
    zip: '93990',
    state: 'Veracruz',
    street: 'Calle San Lerdo de Tejada 219',
    name: 'Sucursal Pánuco',
  }
]

const LoadingContainer = () => <div>cargando...</div>

const GoogleMaps = ({ googleConfig, google }) => {
  const googleMapsApi = useRef(null)
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState(undefined)
  const initialLatitudeRef = useRef(googleConfig.maps.defaultLatitude)
  const initialLongitudeRef = useRef(googleConfig.maps.defaultLongitude)
  const initialZoomRef = useRef(googleConfig.maps.defaultZoom)
  const bounds = new google.maps.LatLngBounds()

  labsCoordinates.forEach(coords => {
    bounds.extend(coords)
  })

  console.log(bounds)

  const handleOnMarkerClick = (props, marker) => {
    setSelectedPlace(props)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const handleOnMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
    }
  }

  const onGoogleMapsReady = (props) => {
    googleMapsApi.current = { maps: props.google.maps, geocoder: new props.google.maps.Geocoder() }
  }

  const markers = labsCoordinates.map(value => {
    return (
      <Marker
        key={value.placeID}
        title={value.name}
        name={value.name}
        position={{ lat: value.lat, lng: value.lng }}
        onClick={handleOnMarkerClick}
        shape={{
          city: value.city,
          colony: value.colony,
          postalCode: value.zip,
          state: value.state,
          street: value.street
        }}
        place={{
          placeId: value.placeID,
          location: { lat: value.lat, lng: value.lng }
        }}
      />
    )
  })

  return (
    <StyledGoogleMaps>
      <StyledWrapper>
        <Map
          google={google}
          initialCenter={{
            lat: parseFloat(initialLatitudeRef.current),
            lng: parseFloat(initialLongitudeRef.current)
          }}
          bounds={bounds}
          onClick={handleOnMapClicked}
          zoom={initialZoomRef.current}
          onReady={onGoogleMapsReady}
        >
          {markers}
          {selectedPlace && (
            <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
              <StyledInfoWindow>
                <StyledInfoWindowContent>
                  <StyledName><StyledParagraph><StyledStrong>{selectedPlace.name}</StyledStrong></StyledParagraph></StyledName>
                  <StyledParagraph>{selectedPlace.shape.street}</StyledParagraph>
                  <StyledParagraph>{selectedPlace.shape.colony}</StyledParagraph>
                  <StyledParagraph>{selectedPlace.shape.postalCode} {selectedPlace.shape.city}</StyledParagraph>
                  <StyledParagraph>{selectedPlace.shape.state}</StyledParagraph>
                  <StyledLink
                    href={
                      `https://www.google.com/maps/search/?api=1&query=${selectedPlace.place.location.lat},${selectedPlace.place.location.lng}&query_place_id=${selectedPlace.place.placeId}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                  <StyledParagraph>¿Cómo llegar?</StyledParagraph>
                </StyledLink>
                </StyledInfoWindowContent>
              </StyledInfoWindow>
            </InfoWindow>
          )}
        </Map>
      </StyledWrapper>
    </StyledGoogleMaps>
  )
}

export default GoogleApiWrapper({
  apiKey: config.google.apiKey,
  language: 'es',
  LoadingContainer: LoadingContainer
})(GoogleMaps)
