// Packages
import styled from 'styled-components'

export const StyledAddressesList = styled.section`
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;    
  }
`

export const StyledWrapper = styled.div`
  padding: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

`

export const StyledAddresses = styled.div`
  column-gap: 1rem;
  display: inline-grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
  }
`

export const StyledAddress = styled.div`
  width: 100%;
`

export const StyledParagraph = styled.p`
  line-height: 1.3;  
  text-align: left;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    text-align: center;
    width: 100%;
  }
`

export const StyledStrong = styled.strong`
  font-weight: 700;
`

export const StyledAddressTitle = styled.h4`
  font-weight: 700;    
  text-align: left;
  width: 100%;
  line-height: 1.3;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    text-align: center;
    width: 100%;
  }
`


export const StyledSectionTitle = styled.h3`  
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;  
    text-align: center;
    line-height: 1.3;
    width: 100%;
  }
`