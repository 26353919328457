// Packages
import React from 'react'

// Components
import ContactPageContent from '../../presentational/ContactPageContent'

// Assets
import useData from '../../../hooks/useData'

const ContactPageContainer = ({ banners }) => {
  const { config } = useData()  
    
  return (
    <ContactPageContent banners={banners} googleConfig={config.google} />
  )
}

export default ContactPageContainer
