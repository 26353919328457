// Packages
import React from 'react'

// Styled components
import { StyledWrapper, StyledContactAddresses, StyledSectionTitle, StyledTitleMapMerged, StyledAddressesList } from './styledComponents'

// Components
import GoogleMaps from '../GoogleMaps'

const ContactAddresses = ({ googleConfig }) => {
  return (
    <StyledContactAddresses>
      <StyledWrapper>
        <StyledTitleMapMerged>
          <StyledSectionTitle>Estamos ubicados en</StyledSectionTitle>
          <GoogleMaps googleConfig={googleConfig} />
        </StyledTitleMapMerged>
        <StyledAddressesList />
      </StyledWrapper>
    </StyledContactAddresses>
  )
}

export default ContactAddresses
