// Packages
import styled from 'styled-components'

export const StyledContactPageDisclaimer = styled.section`
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  margin-top: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) { 
    display: flex;
    justify-content: center;
  }
`

export const StyledWrapper = styled.div`
  display: inline-grid;  
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 85%;    
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 70%;
  }
`

export const StyledCopy = styled.div`      
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    padding: 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    flex-direction: row;
    justify-content: flex-end;    
  }
`

export const StyledSectionTitle = styled.h3`
  font-size: 1.2rem;
  line-height: 1.3;
  text-align: left;
`

export const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 10rem;
  justify-content: center;
  width: 13rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 15rem;
    min-height: 12rem;
  }
`